<template >
  <div class="about">
    <div class="header">
      <Parallax
        :speed="-.25"
        parallaxType="Offset"
        style="position:relative; top:0; right:0; display:block; height:100%; display:block;"
        :marged="false"
        :masked="true"
        :skewed="false"
        :autosize="true"
        :rotate="true"
        :degree="0"
        :minMax="true"
      >
        <v-img :src="headerDev" :contain="false" :height="((isMobile)?20:40)+'vh'" />
      </Parallax>
    </div>
    <v-container style="padding-bottom:140px;">
      <div class="article_content">
        <h1 class="styloText">Développement Web & Mobile</h1>
        <br />
        <p class="styloText first_paragraph">
          <b>
            <span class="surligneur">Bien plus qu'en complémentarité à la conception</span>
          </b>,
          Bige offre un réel savoir faire dans le Développement d'applications Front et Back-end.
          Entre culture et passion,
          <b>
            <span class="surligneur">le développement</span>
          </b> et l'intégration d'interfaces
          sont des facteurs clés à la réussite d'un produit.
          Lorsque le concepteur développe et intègre, il ancre le concept dans sa globalité et n'omet aucun facteur clé
          c'est pour cette raison que le développement
          <b>
            <span class="surligneur">
              est placé
              au
              coeur des services proposés par la Bige.
            </span>
          </b>
        </p>
        <br />
        <!--  <Onscreen :moveY="0">
          <h2 pa-10 class="styloText">:: Propositions</h2>
        </Onscreen>-->
        <v-layout row>
          <v-flex md6 pa-10>
            <Onscreen :moveY="0">
              <div>
                <h2 class="styloText">API design</h2>
                <p class="styloText">
                  Tout back-end nécéssite une interface de programmation avancée et documentée.
                  Toujours en co-conception Bige design des API RESTFUL comme GrraphQL évolutives et permettant la transmission de savoir.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <h2 class="styloText">Déploiement et infrastructure</h2>
                <p class="styloText">
                  Que vous travaillez sur lambda / GCP ou sur des serveurs ou VM classiques, Bige conçoit et développe sous containers
                  destinés à déployer et maintenir les interfaces simplement et sur tout type d'infrastructures.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)" :delay="500">
              <div>
                <h2 class="styloText">Développement de MVP</h2>
                <p class="styloText">
                  Avant tout investissement lourd, Bige s'engage à l'écriture et le déploiement de minimum viable product
                  ainsi qu'à son analyse approfondie destinée à éprouver les produits et maîtriser les investissements
                  liés au développement de produits finis.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <h2 class="styloText">Intégration</h2>
                <p class="styloText">
                  Que ce soit une application complète, une simple landing page ou des emails, Bige c'est s'assurer d'utiliser un réel savoir faire en intégration.
                  Nous ne négligeons jamais l'impact liée à l'intégration et son accessibilité.
                  Les outils et frameworks contemporains demandent un grand savoir faire et des connaissances approfondies dans un nombre de domaines incomensurable.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)" :delay="500">
              <div>
                <h2 class="styloText">Création de composants</h2>
                <p class="styloText">
                  Les applications modernes sont généralement constituées d'un grand nombre de composants d'interface.
                  Bige développe et intégre vos futurs composants compatibles avec les principaux frameworks
                  et propose leur dépôt sur des repository d'entreprise privés de façon à ce que vos développeurs puissent
                  les intégrer et les maintenir d'une simple ligne de commande.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <h2 class="styloText">Extensions</h2>
                <p class="styloText">
                  L'accès à vos outils métiers est parfois complexe et nécéssite souvent une quantité d'onglets et autres applications ouvertes et nuisible à votre productivité.
                  C'est pourquoi Bige est spécialisé dans le dévelooppement d'extensions Chrome destinées à ce que vous ayez toujours accès à vos outils d'un simple clic.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)" :delay="500">
              <div>
                <h2 class="styloText">Applications Desktop</h2>
                <p class="styloText">
                  Ces 10 dernières années nous avons beaucoup amélioré les usages sur mobile,
                  Bige propose le développement d'application Desktop généralement en complémentarité avec vos logiciels d'encaissements
                  et offrant un accès à différents outils dédiés à votre masse salariale n'ayant pas de besoins ou d'accès à du matériel informatique.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <h2 class="styloText">Applications Mobiles</h2>
                <p class="styloText">
                  Après des années à écrire et ré-écrire des applications mobiles en environnement C, Java puis Swift et Kotlin.
                  Bige s'est orienté sur Native Script qui répond parfaitement au cross plateforme et ainsi qu'à la majorité des concepts
                  tout en optimisant les coûts relatif au dé-doublonage liés aux plateformes et également à la transmission de savoir.
                  Nous élaborons également des applications C# sous UNITY pour l'exploitation VR et AR.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)" :delay="500">
              <div>
                <h2 class="styloText">Applications Web & PWA</h2>
                <p class="styloText">
                  Bige développe et intégre des application web progressives destinées à optimiser les usages
                  et offrir un accès mobile rapide et facilement maintenable à vos outils ou produit.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <h2 class="styloText">Études et optimisation</h2>
                <p class="styloText">
                  Sur vos projets courants et en intégration continu il est parfois nécéssaire d'établir des audits complets destinés
                  à optimiser depuis un simple service jusqu'à l'intégration globale. Bige propose d'étudier puis développer et mettre en place des
                  outils destinés à cet effet.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)" :delay="500">
              <div>
                <h2 class="styloText">Transmission de savoir</h2>
                <p class="styloText">
                  Bige développe des composants dédiés à répondre à chaque sous-partie d'un produit et de façon à ce qu'ils puissent être remplacés à souhait.
                  Ces composants sont conçus et construits selon les normes spécifiques à votre équipe de dev
                  et de façon à assurer la transmission de savoirs spécifiques aux outils pour ne jamais vous rendre pieds et poings liés avec Bige.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <h2 class="styloText">En complémentarité</h2>
                <p class="styloText">
                  Bige agit en complémentarité de votre infrastructure existante et ne pratique ni Run ni maintenance sur des développements existants.
                  Ces dernières années nous observons la disparition des infrastructures lourdes et assistons à leur destitution au bénéfice de micro-services,
                  API et composants à usage unique. C'est sur ce type de composants que Bige apporte des réponses tengibles et viables.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:0)" :delay="500">
              <div>
                <h2 class="styloText">Apprentissage continu</h2>
                <p class="styloText">
                  Bige c'est de l'apprentissage en continu et dédie la majorité de son temps aux tests et à la compréhension des usages liés aux nouvelles technologies.
                  Chaque outil et développement proposé est centré sur des technologies à la fois récentes et éprouvées.
                </p>
              </div>
            </Onscreen>
          </v-flex>

          <v-flex md6 pa-10>
            <Onscreen :moveY="((isMobile)?0:121)" :delay="500">
              <div>
                <h2 class="styloText">iOT bluetooth et Wifi</h2>
                <p class="styloText">
                  La conception de prototype card board et la programmation de micro controlllers que ce soit sous Arduino,
                  Raspberry ou tout type de cartes tel que les différents modules ESP WIFI et/ou BLE dans l'objectif d'éprouver un concept Physique
                  est un processus couteux, Bige propose un service de construction de modules sur mesures pour pouvoir faire la preuve d'un concept
                  en disruptant les coûts liés à la gravure.
                </p>
              </div>
            </Onscreen>
          </v-flex>
        </v-layout>
      </div>
    </v-container>
  </div>
</template>
<script>
import Parallax from "@/components/UI/Parallax";
import Onscreen from "@/components/UI/OnScreen";

import headerDev from "@/assets/images/HP/header_developpement.jpg";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { Parallax, Onscreen },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
    fixedTab: {
      get: function () {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return false;
          case "sm":
            return true;
          case "md":
            return true;
          case "lg":
            return true;
          case "xl":
            return true;
          default:
            return true;
        }
      },
    },
  },
  data() {
    return {
      headerDev: headerDev,
    };
  },
  methods: {},
};
</script>